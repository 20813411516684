import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import { LogLevel } from 'msal';
import { version, adB2c } from './common.settings';

export const environment = {
  production: JSON.parse('true'),   // trick to parse boolean string to boolean
  baseUrl: 'https://admin.dev.kazi.co/',
  msalLogLevel: LogLevel.Error,
  version,
  googleTagManagerId: '',
  adB2c,

  api: {
    subscriptionKey: 'f9828a732cf048a2b5ed15a77bab57c7',
    clientId: '577360a7-1249-46af-95ed-63d932b4c574',
    identifyingUrl: 'https://kazi-dev.azure-api.net/identifying/v2',
    taggingUrl: 'https://kazi-dev.azure-api.net/tagging/v2',
    administratorUrl: 'https://kazi-dev.azure-api.net/admin/v2',
    pageSize: parseInt('30', 10)
  },

  sentry: {
    enabled: JSON.parse('false'),
    dsn: 'https://52354eab8e214b4f96a89f76d729eab6@sentry.io/1425679'
  }
};
