import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { TalentScansComponent } from './talentscans/talentscans.component';
import { ReportComponent } from '../shared/components/report/report.component';

export const shellRoutes: Routes = [
    { path: 'talents', component: TalentScansComponent, canActivate: [MsalGuard] },
    { path: 'talents/:id/report', component: ReportComponent, canActivate: [MsalGuard] },
    { path: 'talents/:id/compare/:id2', component: ReportComponent, canActivate: [MsalGuard] },
];

const fullScreenRoutes: Routes = [{ path: 'talents/:id/report/print', component: ReportComponent, canActivate: [MsalGuard] }];

@NgModule({
    imports: [RouterModule.forChild(fullScreenRoutes)],
    exports: [RouterModule],
})
export class TalentScansRoutingModule {}
