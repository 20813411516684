import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ParameterService, Parameters, ReportType } from 'kazi-reports';
import { environment } from '../../../../environments/environment';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { skipLast, filter, switchMap, finalize } from 'rxjs/operators';
import { IdentifyingClient, JobScanDto, TalentScanDto } from '../../api/identifying-api.generated';
import { AdministratorClient, ClientDto } from '../../api/administrator-api.generated';

@Component({
    selector: 'kazi-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
    loaded: boolean;
    error: boolean;
    print: boolean;
    reportType: ReportType;

    subscriptions: Subscription[];

    constructor(
        private parameterService: ParameterService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private userSettingsService: UserSettingsService,
        private router: Router,
        private identifyingClient: IdentifyingClient,
        private administratorClient: AdministratorClient
    ) {
        this.subscriptions = [];

        this.parameterService.parameters = {
            identifyingApiUrl: environment.api.identifyingUrl,
            taggingApiUrl: environment.api.taggingUrl,
            language: this.translate.currentLang,
            theme: 'kazi', // default theme
            themeCDN: 'https://cdn.kazi.be',
        } as Parameters;
    }

    get talentScanReport() {
        return this.reportType === ReportType.TalentScan;
    }

    get jobScanReport() {
        return this.reportType === ReportType.JobScan;
    }

    get isCompareReport() {
        return this.isCompareTalentWithJob || this.isCompareJobWithTalent;
    }

    get isCompareTalentWithJob() {
        return this.reportType == ReportType.CompareTalentWithJob;
    }

    get isCompareJobWithTalent() {
        return this.reportType == ReportType.CompareJobWithTalent;
    }

    ngOnInit() {
        this.loaded = false;
        this.error = false;
        this.print = this.route.snapshot.url.filter((segment) => segment.path === 'print').length > 0;

        if (this.print) {
            document.body.classList.add('body-overflow');
        }

        const id = this.route.snapshot.params.id;
        this.reportType =
            this.route.snapshot.url.filter((segment) => segment.path === 'talents').length > 0 ? ReportType.TalentScan : ReportType.JobScan;
        const isCompare = this.route.snapshot.url.some((segment) => segment.path === 'compare');
        if (isCompare) {
            this.reportType = this.reportType == ReportType.TalentScan ? ReportType.CompareTalentWithJob : ReportType.CompareJobWithTalent;
            this.parameterService.parameters.id2 = this.route.snapshot.params.id2;
        }
        this.parameterService.parameters.id = id;
        this.parameterService.parameters.reportType = this.reportType;
        this.setTheme(id);

        const currentAccountSub = this.userSettingsService.currentAccount$
            .pipe(
                skipLast(1),
                filter((p) => !!p)
            )
            .subscribe((account) => {
                this.router.navigateByUrl(this.talentScanReport ? 'talents' : 'jobs');
            });

        this.subscriptions.push(currentAccountSub);
    }

    private setTheme(id: string) {
        const dtoObervable: Observable<any> =
            this.reportType === ReportType.TalentScan || this.reportType == ReportType.CompareTalentWithJob
                ? this.identifyingClient.getTalentScan(id)
                : this.identifyingClient.getJobScan(id);

        const themeSub = dtoObervable
            .pipe(
                switchMap((item: TalentScanDto | JobScanDto) => this.administratorClient.getClientById(item.clientId)),
                finalize(() => (this.loaded = true)) // loaded=true shows the report
            )
            .subscribe((client: ClientDto) => {
                this.parameterService.parameters.theme = client.theme.toLowerCase();
            });

        this.subscriptions.push(themeSub);
    }

    onError($event) {
        this.error = true;
        const reportTypeName = this.reportType === 1 ? 'talentscan' : 'jobscan';
        throw new Error(`Error loading ${reportTypeName} spider report (${location.href}`);
    }

    showPrintDialog() {
        setTimeout(() => print(), 500);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    compareTalentWithJob({ jobScanId, talentScanId }: { jobScanId: string; talentScanId: string }) {
        console.log(jobScanId, talentScanId);
        this.router.navigate(['talents', talentScanId, 'compare', jobScanId]);
    }

    compareJobWithTalent({ jobScanId, talentScanId }: { jobScanId: string; talentScanId: string }) {
        console.log(jobScanId, talentScanId);
        this.router.navigate(['jobs', jobScanId, 'compare', talentScanId]);
    }
}
